@tailwind base;
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@layer base {

  html {
    @apply leading-tight scroll-smooth;
  }

  body.locked {
    @apply relative overflow-hidden h-full;
  }

  #chatra {
    @apply !left-vw-10 !bottom-vw-10 transition-[bottom];
    right: unset !important;
  }
  body[data-shop-info-shown="true"] #chatra {
    @apply !bottom-vw-64;
  }

  @media screen(sm) {
    body[data-shop-info-shown="true"] #chatra {
      @apply !bottom-vw-10;
    }
  }
}


/* Custom. Not in tailwind's layer block because purge will remove it. */

.before-after-slider__delimiter-icon {
  @apply !bg-primary !w-vw-12 !h-vw-20 !rounded-full relative;
  border: 2.4vw solid white !important;
}
.before-after-slider__delimiter-icon::after {
  content: 'Relax, your apartment will be assembled for you';
  @apply
    w-vw-68
    text-vw-4xl font-bold text-white
    absolute -top-vw-10 left-vw-14;
}

.slick-dots {
  bottom: 1rem;  
}

.slick-dots li {
  width: clamp(.625rem, 3.25vw, 1rem);
  height: clamp(.625rem, 3.25vw, 1rem);
  margin: 0 0.5vw;
}

.slick-dots li button {
  width: clamp(.625rem, 3.25vw, 1rem);
  height: clamp(.625rem, 3.25vw, 1rem);
}

.slick-dots li button:before {
  font-size: clamp(.25rem, 1.875vw, 1rem);
  width: clamp(.625rem, 3.25vw, 1rem);
  height: clamp(.625rem, 3.25vw, 1rem);
  color: white;
  opacity: 0.8;
}

@media screen(sm) {

  .slick-dots li {
    width: clamp(.625rem, 1vw, 1rem);
    height: clamp(.625rem, 1vw, 1rem);
    margin: 0 0.25vw;
  }

  .slick-dots li button {
    width: clamp(.625rem, 1vw, 1rem);
    height: clamp(.625rem, 1vw, 1rem);
  }

  .slick-dots li button:before {
    font-size: clamp(.25rem, 0.67vw, 1rem);
    width: clamp(.625rem, 1vw, 1rem);
    height: clamp(.625rem, 1vw, 1rem);
  }
}

.slick-dots li.slick-active button:before {
  color: #767676;
  opacity: 0.8;
}

@media screen(sm) {
  .before-after-slider__delimiter-icon {
    @apply !w-vw-8 !h-vw-14;
    border: 1vw solid white !important;
  }
  .before-after-slider__delimiter-icon::after {
    @apply w-vw-44 text-vw-2xl top-0 left-vw-10;
  }
}

@tailwind components;

@layer components {

  .centered {
    @apply flex justify-center items-center;
  }
  .centered-x {
    @apply flex justify-center;
  }
  .centered-y {
    @apply flex items-center;
  }

  .container-main {
    @apply max-w-screen-max w-full px-main mx-auto;
  }

  .text-translucent {
    @apply text-black/50;
  }
  .text-outline {
    @apply outline outline-2 outline-offset-6 outline-primary rounded-vw-md;
  }
  .link-main {
    @apply hover:underline;
  }
  .big-ass-button {
    @apply
      sm:text-vw-lg text-vw-4xl font-bold text-white
      bg-primary hover:bg-black sm:rounded-vw-3xl rounded-vw-5xl
      transition-colors
      sm:py-vw-4.5 py-vw-10 sm:px-vw-10 px-vw-24;
  }

  .form-input-base {
    @apply
      sm:h-vw-18 h-vw-44
      sm:text-vw-xl text-vw-5xl
      bg-white sm:rounded-vw-3xl rounded-vw-6xl
      ring-4 ring-transparent focus-visible:ring-primary
      transition-[box-shadow]
      sm:px-vw-8 px-vw-16;
    outline: 0 !important;
  }
  .form-input-base label {
    @apply text-vw-xl text-black;
  }
  .form-input-base .placeholder, .form-placeholder {
    @apply sm:text-vw-xl text-vw-5xl text-black/20;
  }
  .form-input-base:invalid {
    @apply ring-4 ring-red-600;
  }
  .form-text-input {
    @apply form-input-base;
  }
  .form-select-input {
    @apply form-input-base;
  }

  .content {
    @apply sm:text-vw-sm text-vw-2xl;
  }
  .content h2 {
    @apply text-vw-xl font-bold sm:mb-vw-1.5 mb-vw-3;
  }
  .content h3 {
    @apply text-vw-lg font-bold sm:mb-vw-1 mb-vw-2;
  }
  .content ul {
    @apply list-disc sm:pl-vw-5 pl-vw-8 sm:py-vw-2 py-vw-3;
  }
  .content ol {
    @apply list-decimal sm:pl-vw-5 pl-vw-8 sm:py-vw-2 py-vw-3;;
  }
  .content p:empty::after {
    content: '\200b';
  }

  br[desktop-only] {
    @apply sm:display-inherit hidden;
  }
  br[mobile-only] {
    @apply sm:hidden;
  }

}


@tailwind utilities;

@layer utilities {

  .px-main {
    @apply sm:px-vw-14 px-vw-10;
  }

  .display-inherit {
    display: inherit;
  }

  .desktop-only {
    @apply hidden;
  }

  @media screen(sm) {
    .mobile-only {
      @apply hidden;
    }
    .desktop-only {
      @apply display-inherit;
    }
  }

}


/* FAQ accordion */

.faq-item {
  margin-bottom: 1em;
  border-bottom: 1px solid #BFBFBF;
}

.faq-question {
  cursor: pointer;
  padding: 1em;
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  gap: 1em;
}

.faq-question.active {
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 0em 1.5em;
}

.faq-answer.active {
  max-height: 100vh; /* Set to a large value that will be greater than the content */
  padding: 1em 1.5em 2em;
}

.faq-toggle {
  min-width: 1.25em;
  min-height: 1.25em;
  font-size: 1.2em;
  line-height: 1;
  border-width: 0.08em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.show-more {
  margin-top: 1em;
  padding: 0.5em 1.5em;
  gap: 0.5em;
}

.show-more:hover {
  text-decoration: underline; /* Darker shade for hover effect */
}

.slick-slide img {
  height: 100%;
  width: 100%;
}

/* Gallery Page */
.gallery .slick-slide > div {
  margin: 0 5px;
}

@media screen(sm) {
  .gallery .slick-prev, .gallery .slick-next {
    left:0;
    width: 25%;
    height: 100%;
    z-index: 100;
  }

  .gallery .slick-prev::before, .gallery .slick-next::before {
    content: '';
  }

  .gallery .slick-next {
    left: auto;
    right: 0;
  }

  .gallery .slick-track {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    height: 30vw;
  }

  .gallery .slick-slide {
    padding: 5vw 0;
  }
  .gallery .slick-slide > div {
    margin: 0;
  }

  .gallery .slick-slide img {
    transition: all .3s ease;
    transform: scale(.66) translateX(-25%);
    opacity: 0.5;
  }

  .gallery .slick-active {
    z-index: 1;
  }

  .gallery .slick-center {
    z-index: 10;
    cursor: zoom-in;
  }

  .gallery .slick-center img {
  transform: scale(1.5);
  opacity: 1;
  max-height: 100%;
  margin: auto;
  }
  .gallery .slick-center + .slick-active img{
  margin: 0 0 0 auto;
  transform: scale(.66) translateX(25%);
  }

  /* .overflow-hidden {
    overflow: hidden;
  } */

  .zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .zoom-overlay img {
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .zoom-overlay img.zoomed {
    transform: scale(1);
    opacity: 1;
    cursor: zoom-out;
  }
}

/* Blog Page */

.post-modal-content h2 {
  @apply 
    text-vw-5xl font-bold mb-vw-2
    sm:text-vw-3xl;
}

.post-modal-content p {
  @apply 
    mb-vw-4;
}

@media screen(sm) {
  .post-thumbnail {
    width: 40vw;
    height: 22.8vw;
  }

  .even .post-thumbnail {
    order: 2;
    width: 40vw;
    height: 22.8vw;
  }

  .post-content {
    max-width: calc(100% - 45.625vw);
  }

  .post-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
